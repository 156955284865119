/**======================================================================
=========================================================================
Template Name: Light Able - Bootstrap Admin Template
Author: phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

h1,
h2 {
  font-weight: 700;
}

:root {
  --bs-body-bg: #F4F7FA;
  --bs-body-bg-rgb: 244, 247, 250;
  --pc-heading-color: #1D2630;
  --pc-active-background: #F3F5F7;
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #39465F;
  --pc-sidebar-color-rgb: 57, 70, 95;
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: 0px 0px 24px rgba(27, 46, 94, 0.05);
  --pc-sidebar-caption-color: var(--pc-heading-color);
  --pc-sidebar-border: none;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #39465F;
  --pc-header-shadow: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #39465F;
}

[data-pc-sidebar-theme=dark] {
  --pc-sidebar-background: #1D2630;
  --pc-sidebar-color: #FFFFFF;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

[class=layout-3],
[class=layout-nested] {
  --pc-sidebar-background: #1D2630;
  --pc-sidebar-color: #FFFFFF;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
  --pc-header-background: #fff;
  --pc-header-color: #39465F;
}

[class=layout-3] {
  --pc-header-shadow: none;
}

[class=layout-nested] {
  --pc-sidebar-border: 1px solid var(--pc-sidebar-submenu-border-color);
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

[class=layout-modern] {
  --pc-header-background: #212529;
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

[class=layout-advance] {
  --pc-header-background: var(--bs-primary);
  --pc-header-color: #fff;
  --pc-header-shadow: none;
}

[class=layout-extended] {
  --pc-header-background: #212529;
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

section {
  padding: 100px 0;
}

.section-title {
  margin-top: -6px;
}
@media (max-width: 940px) {
  .section-title {
    margin-top: -5px;
  }
}
@media (max-width: 460px) {
  .section-title {
    margin-top: -4px;
  }
}

.title {
  margin-bottom: 50px;
}
.title h2 {
  font-weight: 600;
}
.title h5 {
  font-weight: 500;
}

.landing-page {
  overflow-x: hidden;
  background: var(--bs-body-bg);
}
@media (min-width: 1600px) {
  .landing-page .container {
    max-width: 1200px;
  }
}

.navbar {
  position: fixed;
  padding: 16px 0;
  width: 100%;
  z-index: 1099;
  top: 0;
  backdrop-filter: blur(7px);
  background-color: var(--pc-header-background);
}
.navbar.top-nav-collapse.default {
  box-shadow: none;
}
.navbar.default, .navbar.top-nav-collapse {
  box-shadow: 0 8px 6px -10px rgba(0, 0, 0, 0.5);
}
.navbar .nav-link {
  font-weight: 500;
  color: var(--bs-body-color);
}
.navbar .nav-link:active, .navbar .nav-link:hover, .navbar .nav-link:focus {
  color: var(--bs-primary);
}

header {
  overflow: hidden;
  position: relative;
  padding: 90px 0 0px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
}
header h1 {
  font-size: 55px;
}
header .container {
  position: relative;
  z-index: 5;
}
header .header-content {
  position: relative;
}
header .header-content p {
  color: rgba(255, 255, 255, 0.8);
}
header > * {
  position: relative;
  z-index: 5;
}
header .img-header-bg {
  position: absolute;
  top: 0;
  left: 54%;
  transform: translate(-50%, -50%);
  right: -5%;
  animation: anim-rotate 90s linear infinite;
}
@media (max-width: 1199.98px) {
  header .img-header-bg {
    width: 42%;
  }
}

.home-section {
  padding-top: 90px;
  padding-bottom: 90px;
  border-radius: 40px;
  overflow: hidden;
  min-height: calc(100vh - 120px);
  display: flex;
  align-items: center;
}
@media (min-width: 1400px) {
  .home-section .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 40px;
  }
  .home-section .header-content > .row {
    flex: 1;
    width: 100%;
  }
}
.home-section .header-badge {
  padding: 10px 30px;
  background: rgba(91, 107, 121, 0.5);
  border-radius: 40px;
  border: 1px solid var(--bs-gray-600);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .home-section .header-badge {
    padding: 8px 15px;
  }
}
.home-section .header-badge i {
  font-size: calc(18px + 14 * (100vw - 375px) / 1545);
}
.home-section.home-section-2 {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.home-section.home-section-2 .img-header-main {
  margin-top: 40px;
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  overflow: hidden;
  position: relative;
}
.home-section.home-section-2 .img-header-main:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, transparent 60%, rgba(23, 31, 39, 0.6));
}
@media (min-width: 1400px) {
  .home-section.home-section-2 .header-content {
    gap: 10px;
  }
}

.language-badge {
  padding: 10px 15px;
  background: rgba(91, 107, 121, 0.5);
  border: 1px solid var(--bs-gray-600);
  border-radius: 40px;
  display: block;
  text-align: center;
}
.language-badge img {
  height: 25px;
  width: auto;
}

.bg-dark {
  background: linear-gradient(198deg, #131920 21.56%, #1d2630 48.55%, #131920 91.02%), #f7f9fc;
}

.language-slides {
  padding-top: 99px;
  margin: 0 20px;
}
@media (max-width: 1199.98px) {
  .language-slides {
    padding-top: 70px;
  }
}
@media (max-width: 767.98px) {
  .language-slides {
    padding-top: 50px;
  }
}
@media (max-width: 575.98px) {
  .language-slides {
    padding-top: 30px;
  }
}
.language-slides .swiper-slide {
  opacity: 0.5;
}
.language-slides .swiper-slide.swiper-slide-active, .language-slides .swiper-slide.swiper-slide-prev, .language-slides .swiper-slide.swiper-slide-next {
  opacity: 1;
}

.technology-tabs {
  border: none;
  border-right: 1px solid var(--bs-border-color);
  padding-right: 20px;
  margin-right: 4px;
}
.technology-tabs .nav-link {
  border: none;
  padding: 0;
  background: transparent;
}
.technology-tabs .nav-link .card {
  filter: grayscale(1);
  opacity: 0.6;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.2s ease-in-out;
}
.technology-tabs .nav-link .card .card-body {
  padding: 15px 20px;
}
.technology-tabs .nav-link.active .card,
.technology-tabs .nav-item.show .nav-link .card {
  border-color: var(--bs-border-color);
  filter: grayscale(0);
  opacity: 1;
}

.technology-card {
  border: 1px solid var(--bs-border-color);
  margin-bottom: 0;
  box-shadow: none;
  height: 100%;
}
.technology-card .card-body {
  display: flex;
  align-items: center;
}
.technology-card .card-body > div {
  flex: 1;
}
.technology-card .tech-img {
  max-width: 40px;
  max-height: 40px;
}

.layout-card {
  border: 1px solid var(--bs-border-color);
  margin-bottom: 20px;
  box-shadow: none;
  background: var(--bs-primary-light);
}
.layout-card .card-body {
  padding: 20px 20px 0;
  overflow: hidden;
}
.layout-card .card-body img {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  margin-bottom: -1px;
}

.comminuties-slides.swiper {
  overflow: visible;
}
.comminuties-slides p {
  font-size: 16px;
  color: var(--pc-heading-color);
}

.why-slides .card {
  min-height: 220px;
  border: 1px solid #DBE0E5;
  box-shadow: none;
}
@media (max-width: 575.98px) {
  .why-slides .card {
    min-height: 210px;
  }
}
.why-slides .card p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.board-section {
  position: relative;
}
.board-section > * {
  position: relative;
  z-index: 5;
}
.board-section .img-dots-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  z-index: 1;
}

.bard-block {
  background: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  border: 2px dotted var(--bs-purple);
  padding-top: 80px;
  position: relative;
  overflow: hidden;
}
.bard-block .board-color-option {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 10px;
  border-radius: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
  border: 1px solid var(--bs-border-color);
  border-left: none;
}
.bard-block .board-color-option .board-color-checkbox {
  border-radius: 50%;
  margin: 0;
  width: 35px;
  height: 35px;
  position: relative;
}
.bard-block .board-color-option .board-color-checkbox i {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}
.bard-block .board-color-option .board-color-checkbox .form-check-input {
  width: 35px;
  height: 35px;
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  cursor: pointer;
  margin: 0;
  border: none;
  background: transparent;
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.5);
}
.bard-block .board-color-option .board-color-checkbox .form-check-input ~ i {
  display: none;
}
.bard-block .board-color-option .board-color-checkbox .form-check-input:checked {
  opacity: 0;
}
.bard-block .board-color-option .board-color-checkbox .form-check-input:checked ~ i {
  display: inline-block;
}
.bard-block .board-color-option .board-color-checkbox + .board-color-checkbox {
  margin-top: 15px;
}

.widget-scroll {
  height: 600px;
  background-image: url("../images/landing/widget-bg.png");
  background-size: cover;
  -webkit-animation: mobebg 110s linear infinite reverse;
  animation: mobebg 110s linear infinite reverse;
  background-repeat: repeat-x;
}

@keyframes mobebg {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1920px;
  }
}
.price-card {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  border-radius: 6px;
  padding: 25px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, transparent 50%);
}
.price-card:hover {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  background: linear-gradient(320deg, rgba(255, 255, 255, 0.05) 0%, transparent 50%);
}
.price-card .price {
  font-size: 40px;
  margin: 14px 0px 24px;
  display: block;
  font-weight: 500;
}
.price-card .price-label {
  position: absolute;
  transform: rotate(45deg);
  padding: 5px 0;
  text-transform: uppercase;
  width: 150px;
  text-align: center;
  top: 18px;
  right: -40px;
  font-weight: 600;
  box-shadow: 0 10px 16px 0px rgba(0, 0, 0, 0.2), 0 10px 16px 0px rgba(var(--bs-primary-rgb) 0.15);
}
.price-card ul li {
  position: relative;
  padding-left: 25px;
}
.price-card ul li i {
  position: absolute;
  top: 4px;
  left: 0;
}

.product-section {
  position: relative;
}
.product-section > * {
  position: relative;
  z-index: 5;
}
.product-section .section-title {
  margin-top: -5px;
}
@media (max-width: 818px) {
  .product-section .section-title {
    margin-top: -4px;
  }
}
.product-section .card {
  margin-bottom: 0;
}

.product-cards-block {
  position: relative;
}
.product-cards-block .img-arrow {
  position: absolute;
  right: 0;
  bottom: 100%;
}
.product-cards-block .card {
  min-height: 150px;
  transition: 0.5s;
}
.product-cards-block .card:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}
.product-cards-block .card i {
  font-size: 40px;
}

.price-tabs.nav-pills {
  justify-content: flex-end;
}
.price-tabs.nav-pills .nav-item .nav-link {
  color: var(--bs-gray-700);
}
.price-tabs.nav-pills .nav-link.active,
.price-tabs.nav-pills .show > .nav-link {
  background: var(--bs-gray-900);
  color: var(--bs-gray-300);
}

.widget-tabs.nav-pills {
  background: var(--bs-gray-200);
  display: inline-flex;
  padding: 5px;
}
.widget-tabs.nav-pills .nav-item .nav-link {
  color: var(--bs-body-color);
}
.widget-tabs.nav-pills .nav-link.active,
.widget-tabs.nav-pills .show > .nav-link {
  background: #fff;
}

.counter-block {
  position: relative;
}
.counter-block .img-customer-bg {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .counter-block .img-customer-bg {
    left: 0;
  }
}
.counter-block .img-counter-bg {
  position: absolute;
  right: 45%;
  top: 0;
  transform: translateX(100%);
}
@media (max-width: 991.98px) {
  .counter-block .img-counter-bg {
    right: 0;
    transform: none;
  }
}
.counter-block .counter {
  font-weight: 700;
  font-size: 50px;
}
@media (max-width: 575.98px) {
  .counter-block .counter {
    font-size: 30px;
  }
  .counter-block .counter + h4 {
    font-size: 14px;
  }
}

.platform-img {
  margin-top: -14px;
}
@media (max-width: 991.98px) {
  .platform-img {
    margin-top: -8px;
  }
}
@media (max-width: 767.98px) {
  .platform-img {
    margin-top: -6px;
  }
}
@media (max-width: 476px) {
  .platform-img {
    margin-top: -3px;
  }
}

.footer .footer-top {
  padding: 60px 0;
}
@media (max-width: 991.98px) {
  .footer .footer-top {
    padding: 40px 0;
  }
}
.footer .footer-bottom {
  padding: 22px 0;
}
.footer .footer-link a {
  margin: 14px 0;
  display: block;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  color: var(--bs-primary);
}
@media (max-width: 575.98px) {
  .footer .footer-link a {
    margin: 4px 0;
  }
}
.footer .footer-link a:not(:hover) {
  color: var(--bs-gray-700);
  opacity: 0.9;
}
.footer .footer-link li:last-child a {
  margin-bottom: 0;
}
.footer .footer-sos-link a {
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  color: var(--bs-primary);
}
.footer .footer-sos-link a:not(:hover) {
  color: var(--bs-body-color);
  opacity: 0.9;
}

@media (max-width: 991.98px) {
  section {
    padding: 40px 0;
  }
}
[data-pc-direction=rtl] .owl-carousel {
  direction: ltr;
}
[data-pc-direction=rtl] header .img-header-bg {
  left: 40%;
}

[data-pc-theme=dark].landing-page .bg-white {
  background: #1b232d !important;
  --bs-white: lighten($dark-layout-color, 4%);
}
[data-pc-theme=dark].landing-page .why-slide .card {
  background: #1e2833;
}
[data-pc-theme=dark].landing-page .product-section:after {
  background: #1b232d;
}
[data-pc-theme=dark].landing-page .product-section .card {
  background: #1e2833;
}
[data-pc-theme=dark].landing-page .widget-tabs.nav-pills {
  background: var(--bs-gray-800);
}
[data-pc-theme=dark].landing-page .widget-tabs.nav-pills .nav-link.active,
[data-pc-theme=dark].landing-page .widget-tabs.nav-pills .show > .nav-link {
  background: #131920;
}

@media (max-width: 991.98px) {
  header h1 {
    font-size: 35px;
  }
}
@media (max-width: 767.98px) {
  header .header-content {
    text-align: center;
  }
  header h1 {
    font-size: 30px;
  }
  .bard-block {
    padding-top: 15px;
  }
  .bard-block .board-color-option {
    display: none;
  }
  .price-tabs.nav-pills {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  header h1 {
    font-size: 24px;
  }
}